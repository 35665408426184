<template>
    <div>
        <Dialog v-model:visible='newEditing' modal header='Aggiungi' :style="{ width: '50rem' }"
                :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <div class='grid p-fluid formgrid mt-5'>
                <div class='field col-12'>
                    <span class='p-float-label'>
                        <InputText id='new_answer'
                                   type='text'
                                   v-model="editModel['answer']"

                        />
                        <label for='new_answer'>Risposta</label>
                    </span>
                </div>
            </div>
            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-1'>
                    <span class='p-float-label'>
                        <InputNumber id='new_pts' v-model="editModel['points']" />
                        <label for='new_pts'>Punti</label>
                    </span>
                </div>
            </div>
            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-1'>
                    <span class='p-float-label'>
                        <InputNumber id='new_position' v-model="editModel['num']" />
                        <label for='new_position'>pos</label>
                    </span>
                </div>
            </div>
            <template #footer>
                <Button label='Salva' :disabled='!editModel["answer"]' class='p-button-success' icon='pi pi-check' @click='doCreateNewAnswer'
                        autofocus />
                <Button label='Chiudi' class='p-button-danger ml-3' icon='pi pi-times' @click='newEditing = false' />
            </template>
        </Dialog>
        <Card class='shadow-3'>
            <template #title v-if='editing'>
                <div class='text-right'>
                    <Button icon="pi pi-times" class="p-button-rounded p-button-secondary mb-2" @click='editing = false'/>
                </div>
            </template>
            <template #content>
                <template v-if='!editing'>
                    <h5 v-html='prettified' style='display: inline'></h5>
                    <Button style='float: right' icon="pi pi-trash" class="p-button-rounded p-button-danger ml-2" @click='doDeleteQuestion'/>
                    <Button style='float: right' icon="pi pi-file-edit" class="p-button-rounded p-button-info ml-2" @click='editing = true'/>
                    <div class='mt-3 ml-2'>
                        <h6>Possibili risposte</h6>
                        <div class="mt-3" v-if='value["answers"]'>
                            <Answer v-for='(answer, index) in value["answers"]' :key='answer["id"]' v-model='value["answers"][index]'/>
                        </div>
                        <div class='text-center'>
                            <Button icon="pi pi-plus" class="p-button-rounded p-button-info" @click='startNewEditing'/>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class='grid p-fluid formgrid'>
                        <div class='field col-9  md:col-8'>
                            <div class='p-inputgroup'>
                            <span class='p-float-label'>
                                <InputText :id='"question_"+value["id"]'
                                           type='text'
                                           v-model="value['question']"
                                />
                                <Button icon="pi pi-save" title='Salva' class="p-button-success p-component" :disabled='loading' @click='doUpdate'/>
                                <label :for='"question_"+value["id"]'>Domanda</label>
                            </span>
                            </div>
                        </div>
                        <div class='field col-3 md:col-1'>
                            <div class='p-inputgroup'>
                            <span class='p-float-label'>
                                <InputNumber :id='"q_num_"+value["id"]' mode='decimal' :useGrouping='false'
                                             v-model="value['num']"
                                />
                                <Button icon="pi pi-save" title='Salva' class="p-button-success p-component" :disabled='loading' @click='doUpdate'/>
                                <label :for='"q_num_"+value["id"]'><small>Pos</small></label>
                            </span>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </Card>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('Cont/Quiz');
import Notifications from '../../../mixins/notifications';
import Answer from './Answer.vue';

export default {
    components: { Answer },
    mixins: [ Notifications ],
    emits: ['update:modelValue'],
    data(){
        return {
            editing: false,
            newEditing: false,
            editModel: {},
        }
    },
    computed: {
        ...mapGetters(['loading']),
        prettified(){
            if (!this.value) return "";
            if (!this.value['question']) return "";

            return this.value['question'].replace("-XXX-", "<span class='p-tag p-component p-tag-warning mr-2 pr2up'><span class='p-tag-icon pi pi-question-circle'></span><span class='p-tag-value'>risposta</span></span>");
        },
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
    },
    methods: {
        ...mapActions(['SaveQuestion','DeleteQuestion','CreateAnswer']),
        doCreateNewAnswer() {
            if (!(this.editModel['quiz_id'] && this.editModel['question_id']) ){
                this.ShowError("Attenzione", "Questa risposta non è pronta per essere salvata");
                return;
            }
            this.CreateAnswer(this.editModel).then(() => {
                this.ShowSuccess("Fatto", "Risposta salvata");
            }).catch(err => {
                this.ShowStandardError(err.response);
            }).finally(() => {
                this.newEditing = false;
            });
        },
        doUpdate(){
            //There must be an ID and a QuizId
            if (!this.value['id'] || !this.value['quiz_id']){
                this.ShowError("Attenzione", "Questa domanda non è pronta per essere salvata");
                return;
            }
            this.SaveQuestion(this.value).then(() => {
                this.ShowSuccess("Fatto", "Domanda salvata");
            }).catch(err => {
                this.ShowStandardError(err.response);
            }).finally(() => {
                this.editing = false;
            });
        },
        doDeleteQuestion(){
            this.$confirm.require({
                header: 'Sei sicuro?',
                message: 'Vuoi cancellare questa domanda e tutte le sue risposte?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.DeleteQuestion(this.value).catch(err => {
                        this.ShowStandardError(err.response);
                    });
                },
                reject: () => {
                    //callback to execute when user rejects the action
                }
            });
        },
        startNewEditing() {
            this.newEditing = true;
            this.editModel = {
                quiz_id: this.value['quiz_id'],
                question_id: this.value['id'],
                answer: '',
                points: 0,
                num: 1,
            };
            if (this.value['answers']) this.editModel.num = this.value['answers'].length;
        },
    },
    props: {
        modelValue: {
            default(){
                return {
                    last_update: 0,
                    question: "Inserisci la domanda",
                    answers: [],
                    quiz_id: 0
                }
            }
        }
    }
}
</script>

