<template>
    <div class='grid p-fluid formgrid mt-3'>
        <div class='field col-2  md:col-1'>
            <span class='p-float-label'>
                <InputNumber :id='"q_num_"+value["question_id"]+"_"+value["id"]' mode='decimal' :useGrouping='false'
                             v-model="value['num']"
                />
                <label :for='"q_num_"+value["question_id"]+"_"+value["id"]'>Pos</label>
            </span>
        </div>
        <div class='field col-2  md:col-1'>
            <span class='p-float-label'>
                <InputNumber :id='"q_pt_"+value["question_id"]+"_"+value["id"]' mode='decimal' :useGrouping='false'
                             v-model="value['points']"
                />
                <label :for='"q_pt_"+value["question_id"]+"_"+value["id"]'>Punti</label>
            </span>
        </div>
        <div class='field col-8  md:col-6'>
            <div class='p-inputgroup'>
                <span class='p-float-label'>
                    <InputText :class='{exact: !!value["points"]}' :id='"q_txt_"+value["question_id"]+"_"+value["id"]'
                               v-model="value['answer']"
                    />
                    <Button icon="pi pi-save" title='Salva' class="p-button-success p-component" :disabled='loading' @click='doUpdate'/>
                    <Button icon="pi pi-trash" title='Elimina' class="p-button-danger p-component ml-3" :disabled='loading' @click='doDelete'/>
                    <label :for='"q_txt_"+value["question_id"]+"_"+value["id"]'>Risposta</label>
                </span>
            </div>
        </div>
    </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('Cont/Quiz');
import Notifications from '../../../mixins/notifications';


export default {
    mixins: [ Notifications ],
    emits: ['update:modelValue'],
    data() {

    },
    methods: {
        ...mapActions(['SaveAnswer','DeleteAnswer']),
        doDelete(){
            this.$confirm.require({
                header: 'Sei sicuro?',
                message: 'Vuoi cancellare questa risposta?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.DeleteAnswer(this.value).catch(err => {
                        this.ShowStandardError(err.response);
                    });
                },
                reject: () => {
                    //callback to execute when user rejects the action
                }
            });
        },
        doUpdate(){
            //There must be an ID and a QuizId
            if (!this.value['id'] || !this.value['quiz_id'] || !this.value['question_id']){
                this.ShowError("Attenzione", "Questa risposta non è pronta per essere salvata");
                return;
            }
            this.SaveAnswer(this.value).then(() => {
                this.ShowSuccess("Fatto", "Risposta salvata");
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
    },
    computed: {
        ...mapGetters(['loading']),
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            },
        },
    },
    props: {
        modelValue: {},
    },
};
</script>

<style scoped>
.exact {
    background-color: #caffca;
}
</style>
