<template>
    <div>
        <Dialog v-model:visible='newEditing' modal header='Nuova domanda' :style="{ width: '50rem' }"
                :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <div class='grid p-fluid formgrid mt-5'>
                <div class='field col-12'>
                    <span class='p-float-label'>
                        <InputText id='new_question'
                                   type='text'
                                   v-model="editModel['question']"

                        />
                        <label for='new_question'>Domanda</label>
                    </span>
                </div>
            </div>
            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-1'>
                    <span class='p-float-label'>
                        <InputNumber id='new_position' v-model="editModel['num']" />
                        <label for='new_position'>pos</label>
                    </span>
                </div>
            </div>
            <template #footer>
                <Button label='Salva' :disabled='!editModel["question"]' class='p-button-success' icon='pi pi-check' @click='doCreateNewQuestion'
                        autofocus />
                <Button label='Chiudi' class='p-button-danger ml-3' icon='pi pi-times' @click='newEditing = false' />
            </template>
        </Dialog>
        <Card>
            <template #title>
                <h5 v-if='id === ""'>Nuovo record</h5>
                <h5 v-else>
                    <Flag v-if='model && model["locale"]' :code='model["locale"]' :name='strLang' :width='24'
                          :class="{'mr-1': true}" />
                    {{ pageTitle }} (in {{ strLang }})
                </h5>
            </template>
            <template #content v-if='row'>
                <div class='text-right last_update'>Data ultima modifica: {{ lastUpdateDate }}</div>
                <Toolbar>
                    <template #start>
                        <Button icon='pi pi-plus' title='Aggiungi domanda' class='mr-2 p-button-info'
                                @click='startNewEditing' />
                        <Button icon='pi pi-trash' class='mr-2 p-button-danger' title='Elimina questo Quiz' @click='doDelete'/>
                        <InputSwitch v-if='model' :modelValue="model.active" title='Attiva' @click='activate'/>
                    </template>
                </Toolbar>
                <div class='grid mt-3'>
                    <div class='col-12' v-for='(question, index) in model["questions"]' :key='question["id"]'>
                        <Question v-model='model["questions"][index]' />
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('Cont/Quiz');
const commonStore = createNamespacedHelpers('Common');

import Factory from './model';
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import Guards from '../../../mixins/guards';
import Question from './Question.vue';

export default {
    beforeRouteLeave(to, from, next) {
        this.beforeRouteLeaveCheck(to, from, next);
    },
    beforeRouteUpdate(to, from, next) {
        this.beforeRouteUpdateCheck(to, from, next);
    },
    data() {
        return {
            newEditing: false,
            editModel: {},
        };
    },
    mounted() {
        this.loadData(this.id);
    },
    computed: {
        ...mapGetters(['row', 'loading', 'dirtyForm']),
        ...commonStore.mapGetters(['languages']),
        strLang() {
            if (this.model && this.model['locale'] && this.languages && this.languages[this.model['locale']]) {
                return this.languages[this.model['locale']]['label'];
            }
            return '';
        },
    },
    methods: {
        ...mapActions(['fetchOne', 'CreateQuestion', 'DeleteQuiz', 'ToggleQuizActivation']),
        ...mapMutations(['setRow', 'formIsClean']),
        doCreateNewQuestion() {
            if (!this.editModel['quiz_id']){
                this.ShowError("Attenzione", "Questa domanda non è pronta per essere salvata");
                return;
            }
            if (!this.editModel['question'].includes("-XXX-")){
                this.ShowWarning("Attenzione", "Avete salvato una domanda senza una parte incognita (-XXX-)")
            }
            this.CreateQuestion(this.editModel).then(() => {
                this.ShowSuccess("Fatto", "Domanda salvata");
            }).catch(err => {
                this.ShowStandardError(err.response);
            }).finally(() => {
                this.newEditing = false;
            });
        },
        startNewEditing() {
            this.newEditing = true;
            this.editModel = {
                quiz_id: this.model.id,
                question: '',
                num: 1,
            };
            if (this.model['questions']) this.editModel.num = this.model['questions'].length;
        },
        activate(){
            const message = this.row['active'] ? "Dopo questa operazione non ci saranno quiz attivi sul libro oggetto del quiz sul frontend." : "Dopo questa operazione questo quiz sarà visibile sul frontend sostituendo eventuali altri quiz sullo stesso libro";
            this.$confirm.require({
                header: 'Sei sicuro?',
                message: message,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.ToggleQuizActivation(this.model.id).then(() => {
                        //Nothing to do
                    }).catch(err => {
                        this.ShowStandardError(err.response);
                    });
                },
                reject: () => {
                    //callback to execute when user rejects the action
                }
            });
        },
        loadData(id) {
            if (id === '') {
                this.setRow(Factory.newModel(this.languages));
            } else {
                this.fetchOne(id).then(res => {
                    this.setPageTitle('Modifica il quiz sul libro: ' + res['book_slug']);
                    if (!res['active']){
                        this.ShowWarning("ATTENZIONE", "Questo quiz non è ancora attivo e quindi non è visibile nel frontend. Attivarlo con l'apposito switch in Toolbar.", 5000)
                    }
                }).catch(err => {
                    this.ShowStandardError(err.response);
                }).finally(() => {
                });
            }

        },
        doDelete(){
            this.$confirm.require({
                header: 'Sei sicuro?',
                message: 'Vuoi cancellare tutto il quiz?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.DeleteQuiz(this.model.id).then(() => {
                        this.navigateToBase();
                        //we have to go to the list
                    }).catch(err => {
                        this.ShowStandardError(err.response);
                    });
                },
                reject: () => {
                    //callback to execute when user rejects the action
                }
            });
        },
    },
    props: {
        id: {
            default: '',
            type: String,
        },
    },
    watch: {
        id(n, o) {
            if (n !== o) {
                this.loadData(n);
            }
        },
        row(n) {
            this.model = n;
        },
    },
    mixins: [
        Notifications,
        Navigations,
        Guards,
    ],
    components: {
        Question,
    },
};
</script>
