import tools from '../../../libs/tools';
import { required } from '@vuelidate/validators';

const model = {
    id: "",
    last_update: 0,
    locale: "",
    book_id: "",
    book_slug: ""
};

export default {
    ...tools.models.apiCommons(model, {}, "book_id"),
    modelValidation: {
        model: {
            lang: {
                required,
            },
            book_id: {
                required,
            },
        }
    },
    uploads: []
};
